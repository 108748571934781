import React from 'react'
import Logs from '../components/oberoiLogs';
import { useState, useEffect } from 'react';
import axios from "axios";

const oberoiCheckout = () => {
    const [logs, setLogs] = useState(null);

  useEffect(() => {
    const getLogs = async () => {
      try {
        const { data } = await axios.get(
          `https://monitor-oberoi360-api.parkit.biz/arohi/checkout-logs`
        );
        console.log(data.record.rows);
        setLogs(data.record.rows);
      } catch (e) {
        console.log(e);
      }
    }
    getLogs();
  }, []);

  return (
    <Logs logs={logs} tag="checkout" head="Oberoi Checkout Logs" />
  )
}

export default oberoiCheckout;