import React from 'react'
import Logs from '../components/atriaLogs';
import { Box, Button, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import axios from "axios";

const atriaCheckin = () => {
  const [logs, setLogs] = useState(null);
  const [count, setCount] = useState();
  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
  })

  useEffect(() => {
    const getLogs = async () => {
      try {
        const { data } = await axios.get(
          `https://monitor-atriamall-entry.parkit.biz/v1/atria-mall/arohi-checkin`
        );
        setCount(data.record.count);
        setLogs(data.record.rows);
      } catch (e) {
        console.log(e);
      }
    }
    getLogs();
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const handleFilterSubmit = async(e) => {
    e.preventDefault();
    try{
      //use format function to convert date to string
      let date1 = new Date (filter.startDate);
      let date2 = new Date (filter.endDate);
      const startDate = formatDate(date1);
      const endDate = formatDate(date2);
      console.log(startDate, endDate);
      const {data} = await axios.get(`https://monitor-atriamall-entry.parkit.biz/v1/atria-mall/arohi-checkin`, {
        params: {
          startDate,
          endDate
        }
      })
      setCount(data.record.count);
      setLogs(data.record.rows);
    }catch(err){
      console.log(err);
    }
  }

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt:3 }}>
        <form onSubmit={handleFilterSubmit}>
        <Box sx={{ maxWidth:'900px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ maxWidth: '100%', width: '100%' }} adapterLocale={"en"}>
            <DateTimePicker
              sx={{ ml: 2, mr: 2 }}
              fullWidth
              label="Start Date Time"
              openTo="year"
              views={['year', 'month', 'day']}
              value={filter.startDate}
              onChange={(newValue) => {
                setFilter({
                  ...filter,
                  startDate: newValue,
                });
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
            <DateTimePicker
              sx={{ ml: 2, mr: 2 }}
              fullWidth
              label="End Date Time"
              openTo="year"
              views={['year', 'month', 'day']}
              value={filter.endDate}
              onChange={(newValue) => {
                setFilter({
                  ...filter,
                  endDate: newValue,
                });
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
          <Button sx={{mr:1, ml:1}} variant='contained' type="submit" >Filter</Button>
        </Box>
        </form>
      </Box>
      <Logs logs={logs} head="Atria CheckIn Logs" tag="checkin" count={count} />
    </>
  )
}

export default atriaCheckin