import React from "react";
import "./App.css";
import Navbar from "./components/navbar";
import AtriaCheckin from "./pages/atriaCheckin"
import AtriaCheckout from "./pages/atriaCheckout";
import OberoiCheckin from "./pages/oberoiCheckin";
import OberoiCheckout from "./pages/oberoiCheckout";
import {BrowserRouter, Routes, Route} from 'react-router-dom';

function App() {

  return (
    <BrowserRouter>
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<AtriaCheckin />} />
        <Route path="/atria-checkin" element={<AtriaCheckin />} />
        <Route path="/atria-checkout" element={<AtriaCheckout />} />
        <Route path="/oberoi-checkin" element={<OberoiCheckin />} />
        <Route path="/oberoi-checkout" element={<OberoiCheckout />} />
      </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
