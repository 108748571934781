import * as React from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';

const Logs = (props) => {
    const { logs, head, tag, count } = props;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <Box sx={{height:'100%', width: '100%'}}>
                <div>
                    <h1>{head}</h1>
                    {
                        count ? <h3>Count: {count}</h3> : <h3>Loading...</h3>
                    }
                </div>
                <Paper sx={{ width: '100%', maxWidth: '1200px', margin: 'auto', overflow: 'hidden' }}>
                    <TableContainer component={Paper}>
                        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">#</TableCell>
                                    <TableCell align="center">Timestamp</TableCell>
                                    <TableCell align="center">Vehicle Number</TableCell>
                                    <TableCell align="center">Image 1</TableCell>
                                    <TableCell align="center">Image 2</TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                logs ?
                                    (<TableBody>
                                        {
                                            logs
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((log, index) => {
                                                    return (
                                                        <TableRow key={log.id}>
                                                            <TableCell align="center">{log.id}</TableCell>
                                                            <TableCell align="center">{tag === "checkin" ? log.redableCheckInTimestamp
                                                                .dateTime : log.redableCheckOutTimestamp.dateTime}</TableCell>
                                                            <TableCell align="center">
                                                                {log.vehicleRegistration}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {
                                                                    tag === "checkin" ?
                                                                        <img src={`https://monitor-atriamall-entry.parkit.biz/${log.checkInImage1}`} alt="checkin 1" height={75} />
                                                                        : <img src={`https://monitor-atriamall-exit.parkit.biz/${log.checkOutImage1}`} alt="checkout 1" height={75} />
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {
                                                                    tag === "checkin" ?
                                                                        <img src={`https://monitor-atriamall-entry.parkit.biz/${log.checkInImage2}`} alt="checkin 2" height={75} />
                                                                        : <img src={`https://monitor-atriamall-exit.parkit.biz/${log.checkOutImage2}`} alt="checkout 2" height={75} />
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                        }
                                    </TableBody>) : null
                            }
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={logs ? logs.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </>
    )
}

export default Logs;